import { CartItem, CartResponse } from "@ignite/api/cart"
import { ContentResponse } from "@ignite/api/contents"
import { getCurrency } from "@ignite/utils/currency"
import emitEvent from "@ignite/utils/eventEmitter"
import { ProductCardProps } from "components/ProductCard"
import { useGetContents } from "hooks/queries/contents"
import { useGetSiteSettings } from "hooks/queries/siteSettings"
import { ProductModel, ProductVariant } from "pages/ProductPage"
import { useEffect } from "react"

import { pageParameters } from "./constants"

export type ConfirmedOrder = {
  /** Ordernumber */
  o: string

  /** Revenue */
  r: number

  /** Shipping */
  s: number

  /** Total Tax */
  t: number

  /** Coupons */
  c: string[]

  /** Products */
  p: ConfirmedOrderLine[]
}

export type ConfirmedOrderLine = {
  /** Name */
  n: string // Name

  /** Code */
  c: string

  /** Price */
  p: number

  /** Brand */
  b: string

  /** Categories */
  ct: string

  /** Tags */
  t: string

  /** Variant */
  v: string

  /** Quantity */
  q: number
}

const DataLayerPublisher = () => {
  const { data: page } = useGetContents()
  const { data: siteSettings } = useGetSiteSettings()

  useEffect(() => {
    if (!siteSettings?.enableDataLayer) {
      return
    }
    const pageParams: typeof pageParameters = {
      ...pageParameters,
      content_group: (page as any)?.properties?.fluidoPageSubject,
      created_at: page?.meta.createdAt ?? "not set",
      published_at: page?.meta.publishedAt ?? "not set",
      updated_at: page?.meta.updatedAt ?? "not set",
    }
    if (window.BOT_DETECTED) return

    const eventListener = (e: Event) => {
      const customEvent = e as CustomEvent
      const emittedEvent = customEvent.detail

      const payload = getPayloadByEvent(emittedEvent, page, pageParams)
      if (window["ENVIRONMENT"] != "Production") {
        if (window["dataLayer"] == undefined) {
          window["dataLayer"] = []
        }
      }

      if (emittedEvent && window["dataLayer"] && payload) {
        window["dataLayer"].push({ ecommerce: null }) // clear the previous ecommerce object.
        window["dataLayer"].push(payload)
      }
    }
    window.addEventListener("igniteEvent", eventListener)

    return () => {
      window.removeEventListener("igniteEvent", eventListener)
    }
  }, [page, siteSettings])

  return null
}

export const removeNonAscii = (str: string) =>
  str
    ?.trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")

const getFriendlyPageName = (pageType: string) => {
  switch (pageType) {
    case "IgniteProduct":
      return "product page"

    case "IgniteCategory":
      return "category page"

    case "StartPage":
      return "start page"

    default:
      return pageType
  }
}

const getGA4Event = (eventType: string) => {
  switch (eventType) {
    case "virtualPageView":
      return "virtual_page_view"

    case "productClick":
      return "select_item"

    case "productImpression":
      return "view_item_list"

    case "productCollectionImpression":
      return "view_item_list"

    case "productPageView":
      return "view_item"

    case "viewCart":
      return "view_cart"

    case "addToCart":
      return "add_to_cart"

    case "removeFromCart":
      return "remove_from_cart"

    case "checkout":
      return "begin_checkout"

    case "addShippingInfo":
      return "add_shipping_info"

    case "addPaymentInfo":
      return "add_payment_info"

    case "purchase":
      return "purchase"

    case "missingComponent":
      return "missing_component"
    case "customEvent":
      return "custom_event"
    default:
      return eventType
  }
}

const getPayloadByEvent: any = (
  emittedEvent: any,
  page: ContentResponse | any,
  pageParams: typeof pageParameters
) => {
  if (!emittedEvent) return

  if (!emittedEvent.data) return

  const currency = getCurrency()

  switch (emittedEvent.eventType) {
    case "productClick":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          value: parseFloat(emittedEvent.data.product.minPrice),
          currency: currency.toUpperCase(),
          items: [
            {
              ...normalizeProduct(
                emittedEvent.data,
                page,
                emittedEvent.data.list,
                emittedEvent.data.position
              ),
            },
          ],
        },
      }
    case "productImpression":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          total_list_value: totalValue(emittedEvent.data.products),
          items: [
            {
              ...normalizeProduct(
                emittedEvent.data,
                page,
                emittedEvent.data.list,
                emittedEvent.data.position
              ),
            },
          ],
        },
      }

    case "productCollectionImpression":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          total_list_value: totalValue(emittedEvent.data.products),
          currency: currency.toUpperCase(),
          items: (emittedEvent.data.products as ProductCardProps[]).map(
            (product, i) => ({
              ...normalizeProduct(product, page, emittedEvent.data.list, i + 1),
            })
          ),
        },
      }

    case "productPageView":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value: emittedEvent.data.selectedVariant
            ? emittedEvent.data.selectedVariant.price.amount
            : emittedEvent.data.currentPrices.length > 0
            ? emittedEvent.data.currentPrices[0].value
            : 0,
          items: [
            {
              ...normalizeVariant(emittedEvent.data),
            },
          ],
        },
      }

    case "viewCart":
      return emittedEvent.data.cart
        ? {
            event: getGA4Event(emittedEvent.eventType),
            ecommerce: {
              currency: currency.toUpperCase(),
              value: emittedEvent.data.cart.total,
              items: (emittedEvent.data.cart as CartResponse).items.map(
                (cartItem) => ({
                  ...normalizeCartItem(cartItem),
                })
              ),
            },
          }
        : {
            event: getGA4Event(emittedEvent.eventType),
            ecommerce: {
              currency: currency.toUpperCase(),
              value: 0,
              items: [],
            },
          }

    case "addToCart":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value: emittedEvent.data.lineItem.price,
          items: [
            {
              ...normalizeCartItem(emittedEvent.data.lineItem),
            },
          ],
        },
      }

    case "removeFromCart":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value:
            emittedEvent.data.lineItem.price *
            emittedEvent.data.lineItem.quantity,
          items: [
            {
              ...normalizeCartItem(emittedEvent.data.lineItem),
            },
          ],
        },
      }

    case "addShippingInfo":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value: emittedEvent?.data?.checkout?.cart?.total,
          shipping_tier: "online delivery",
          coupon: emittedEvent?.data?.checkout?.couponCodes?.map(
            (coupon: string) => coupon
          ),
          items: (
            emittedEvent?.data?.checkout?.cart as CartResponse
          )?.items?.map((cartItem) => ({
            ...normalizeCartItem(cartItem),
          })),
        },
      }

    case "addPaymentInfo":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value: emittedEvent.data.checkout.cart.total,
          payment_type: emittedEvent.data.checkout.properties.IsPrivateCustomer
            ? "private"
            : "company",
          items: (emittedEvent.data.checkout.cart as CartResponse).items.map(
            (cartItem) => ({
              ...normalizeCartItem(cartItem),
            })
          ),
        },
      }

    case "purchase": {
      try {
        return {
          event: getGA4Event(emittedEvent.eventType),
          ecommerce: {
            transaction_id: emittedEvent.data.orderDetails.orderId,
            shipping:
              parseInt(emittedEvent.data.orderDetails?.shippingTotalString) ||
              0,
            coupon:
              emittedEvent.data.orderDetails?.discountTotalString !== "0 kr"
                ? ["has discount"]
                : [],
            currency: currency.toUpperCase(),
            value: emittedEvent.data.orderDetails?.total,
            tax: emittedEvent.data.orderDetails?.taxTotal,
            items: (emittedEvent.data.orderDetails as CartResponse).items.map(
              (cartItem) => ({
                ...normalizeCartItem(cartItem),
              })
            ),
          },
        }
      } catch (error) {
        console.log(error)
        break
      }
    }
    case "promotionClick":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: emittedEvent.data.id,
                name: emittedEvent.data.name,
                creative: emittedEvent.data.creative,
                position: emittedEvent.data.position,
              },
            ],
          },
        },
      }

    case "promoView":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: emittedEvent.data.id,
                name: emittedEvent.data.name,
                creative: emittedEvent.data.creative,
                position: emittedEvent.data.position,
              },
            ],
          },
        },
      }

    case "virtualPageView":
      return {
        event: getGA4Event(emittedEvent.eventType),
        page_type: getFriendlyPageName(page.type).toLowerCase(), // friendly name
        page_location: emittedEvent.data.page_location,
        page_path: emittedEvent.data.page_path,
        page_title: emittedEvent.data.page_title.toLowerCase(),
        ...pageParams,
      }

    case "changeLanguage":
      return {
        event: getGA4Event(emittedEvent.eventType),
        fromLanguage: emittedEvent.data.fromLanguage,
        toLanguage: emittedEvent.data.toLanguage,
      }

    case "clickOnSearch":
      return {
        event: getGA4Event(emittedEvent.eventType),
      }

    case "checkout":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ecommerce: {
          currency: currency.toUpperCase(),
          value: emittedEvent.data.cart.total,
          coupon: emittedEvent.data.checkout.couponCodes.map(
            (coupon: string) => coupon
          ),
          items: (emittedEvent.data.cart as CartResponse).items.map(
            (cartItem) => ({
              ...normalizeCartItem(cartItem),
            })
          ),
        },
      }

    case "productExpanders":
      return {
        event: getGA4Event(emittedEvent.eventType),
        interaction: "click",
        name: emittedEvent.data.name,
        clickToState: emittedEvent.data.clickToState ? "open" : "close",
      }

    case "sortProduct":
      return {
        event: getGA4Event(emittedEvent.eventType),
        optionSelected: emittedEvent.data.option,
      }

    case "productFiltering":
      return {
        event: getGA4Event(emittedEvent.eventType),
        filteringCategorySelected: emittedEvent.data.category,
        filteringOptionSelected: emittedEvent.data.value,
      }

    case "highlightCard":
    case "blogCard":
    case "mediaCard":
    case "heroEngagements":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ctaType: emittedEvent.data.ctaType,
        message: emittedEvent.data.message,
        cta: emittedEvent.data.cta,
        clickToURL: emittedEvent.data.clickToURL,
      }
    case "missingComponent":
      return {
        event: getGA4Event(emittedEvent.eventType),
        baseType: emittedEvent.data.baseType,
        type: emittedEvent.data.type,
      }
    case "customEvent":
      return {
        event: getGA4Event(emittedEvent.eventType),
        ...emittedEvent.data,
      }

    default:
      return null
  }
}
export const getClosestLowBreakpoint = (arr: number[], value: number) =>
  arr.reverse().find((e) => e <= value)

//Might seem weird to set and emit, but this is where we have the data in an already ordered manner.
export const setAllLowerBreakpointsTrueandEmit = (
  breakpoints: videoBreakpoints,
  highestValue: number,
  params: object
) => {
  const result = breakpoints
  for (const key in breakpoints) {
    const bpNumber = Number(key)
    if (bpNumber && bpNumber <= highestValue && breakpoints[key] === false) {
      result[key] = true
      const newParams = { ...params, video_percent: bpNumber }
      emitEvent("customEvent", newParams)
    }
  }
  return result
}

function checkInt(value: number | undefined) {
  return Number.isInteger(value) && value !== undefined && value > -1
}

function normalizeProduct(
  data: any,
  page?: ContentResponse,
  list?: string,
  position?: number
) {
  const product = data.product
    ? (data.product as ProductCardProps) // productImpression
    : (data as ProductCardProps) // productCollectionImpression

  return {
    item_id:
      (product.productModel &&
        (typeof product.productModel.uid === "string"
          ? product.productModel.uid.toLowerCase()
          : product.productModel.uid)) ||
      (typeof product.displayId === "string"
        ? product.displayId.toLowerCase()
        : product.displayId) ||
      "n/a",
    item_name: removeNonAscii(product.productName || "").toLowerCase(),
    price: product.minPrice ? parseFloat(product.minPrice) : product.minPrice,
    item_brand:
      typeof product.brand === "string"
        ? removeNonAscii(product.brand).toLowerCase()
        : "ihm",
    item_category: removeNonAscii(product.categoryName || "n/a").toLowerCase(),
    item_list_name: page
      ? `${getFriendlyPageName(page.type).toLowerCase()}${
          list?.toLowerCase() ? " | " + list.toLowerCase() : ""
        }`
      : "n/a",
    index: checkInt(product.index)
      ? product.index
      : checkInt(position)
      ? Number(position) - 1
      : "",
  }
}

function normalizeVariant(data: any) {
  const selectedVariant =
    data.selectedVariant !== undefined
      ? (data.selectedVariant as ProductVariant)
      : undefined
  const productModel = data.productModel as ProductModel
  return selectedVariant
    ? {
        item_id:
          (typeof productModel.uid === "string"
            ? productModel.uid.toLowerCase()
            : productModel.uid) ||
          (typeof selectedVariant.salesforceId === "string"
            ? selectedVariant.salesforceId.toLowerCase()
            : selectedVariant.salesforceId) ||
          (typeof selectedVariant.uid === "string"
            ? selectedVariant.uid.toLowerCase()
            : selectedVariant.uid) ||
          "n/a",
        item_name: `${removeNonAscii(
          productModel.name || ""
        ).toLowerCase()}- variantview ${
          selectedVariant !== undefined ? selectedVariant["selectionLabel"] : ""
        }`,
        price: selectedVariant.price.amount || 0,
        item_brand: "ihm",
        item_category: removeNonAscii(
          selectedVariant.utbildning || "n/a"
        ).toLowerCase(),
        index: undefined, //No use case for this yet...
      }
    : {
        item_id:
          (typeof productModel.uid === "string"
            ? productModel.uid.toLowerCase()
            : productModel.uid) || "n/a",
        item_name: `${removeNonAscii(
          productModel.name || ""
        ).toLowerCase()}- variantview ${
          selectedVariant !== undefined ? selectedVariant["selectionLabel"] : ""
        }`,
        index: 0,
        item_brand: "ihm",
        price: data.currentPrices.value || 0,
      }
}

function normalizeCartItem(lineItem: any) {
  const product = lineItem as CartItem
  const isRegistrationFee = product.code === "Anmalningsavgift_1"
  return {
    item_id: isRegistrationFee
      ? removeNonAscii(product.displayName || "").toLowerCase()
      : product?.properties?.ProductUid?.toLowerCase() || "n/a",
    item_name: removeNonAscii(product.displayName || "").toLowerCase(),
    price: product.price,
    quantity: product.quantity,
    item_brand: "ihm",
    item_category: isRegistrationFee
      ? removeNonAscii(product.displayName || "").toLowerCase()
      : typeof product.properties.Utbildning === "string"
      ? product.properties.Utbildning.toLowerCase()
      : product.properties.Utbildning || "n/a",
    item_variant:
      typeof product.code === "string"
        ? product.code.toLowerCase()
        : product.code || "n/a",
  }
}

function totalValue(products: ProductCardProps[]) {
  return products.reduce(
    (acc, product) => acc + parseFloat(product.minPrice || "0"),
    0
  )
}

//DL-related shared types
export type videoBreakpoints = {
  10: boolean
  25: boolean
  50: boolean
  75: boolean
  90: boolean
}
export default DataLayerPublisher
